import request from "@/utils/request";

/**
 * 获取订阅方案
 * @param { String } extensions || 文件名
 */
export const getPacakges = () => {
  return request({
    url: "/api/app/pacakges",
    method: "get"
  });
};